<template>
    <div class="container-fluid view clients">
        <div class="content-view borderless without-sidebar">

            <div class="table-view clients-table-view">
                <div class="action-header">
                    <h1>{{ $t('Clients') }}</h1>
                    <div class="actions">
                        <button type="button" class="btn btn-grey" @click="showAddComponent=true">
                            {{ $t('Add') }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.285" height="18.285"
                                 viewBox="0 0 18.285 18.285">
                                <g>
                                    <g>
                                        <path
                                            d="M8.148 2.5H4.87A2.37 2.37 0 0 0 2.5 4.87v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM18.415 2.5h-3.278a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339h-3.278a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM8.148 12.767H4.87a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37v-3.278a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339v-3.278a.339.339 0 0 1 .339-.337h3.278a.339.339 0 0 1 .339.339zm12.3-1.639a1.016 1.016 0 0 1-1.016 1.016h-1.979v1.978a1.016 1.016 0 1 1-2.032 0v-1.978h-1.977a1.016 1.016 0 1 1 0-2.032h1.977v-1.977a1.016 1.016 0 1 1 2.032 0v1.977h1.978a1.016 1.016 0 0 1 1.015 1.016z"
                                            transform="translate(-2.5 -2.5) translate(2.5 2.5) translate(-2.5 -2.5)"/>
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-grey" @click="getCompanies">
                            {{ $t('Refresh') }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.948" height="20" viewBox="0 0 20.948 20">
                                <g>
                                    <g>
                                        <path
                                            d="M10 13a8.37 8.37 0 0 1 7.528 4.47 3 3 0 0 1-2.58 4.53h-9.9a3 3 0 0 1-2.581-4.53A8.37 8.37 0 0 1 10 13zm0 2a6.383 6.383 0 0 0-5.808 3.49 1 1 0 0 0 .86 1.51h9.9a1 1 0 0 0 .86-1.51A6.383 6.383 0 0 0 10 15zm9-7a1 1 0 0 1 .993.883L20 9v2h2a1 1 0 0 1 .117 1.993L22 13h-2v2a1 1 0 0 1-1.993.117L18 15v-2h-2a1 1 0 0 1-.117-1.993L16 11h2V9a1 1 0 0 1 1-1zm-8.833-6a5 5 0 0 1 0 10H10a5 5 0 0 1 0-10h.167zm-.083 2h.083a3 3 0 0 0-.083 6H10a3 3 0 0 0 .083-6z"
                                            transform="translate(-820.052 268) translate(818 -270)"/>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>

                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ $t('Owner') }}</th>
                        <th scope="col">{{ $t('Name') }}</th>
                        <th scope="col">{{ $t('Status') }}</th>
                        <th scope="col">{{ $t('Created at') }}</th>
                        <th scope="col">{{ $t('Updated at') }}</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="company in companies" :key="company.id">
                        <th scope="row">{{ company.id }}</th>
                        <td>
                            <router-link :to="{ name: 'ViewUser', params: { id: company.owner_id }}">{{
                                    company.owner
                                }}
                            </router-link>
                        </td>
                        <td>{{ company.name }}</td>
                        <td class="pe-20">
                            <Multiselect
                                :canClear="false"
                                v-model="company.status_id"
                                :options="companyStatuses"
                                :placeholder="$t('Select status')"
                                class="form-control"
                                @input="companyStatusChanged(company.id, $event)"
                            />
                        </td>
                        <td>{{ company.created_at }}</td>
                        <td>{{ company.updated_at }}</td>
                        <td class="text-end">
                            <router-link :to="{ name: 'ViewClient', params: { id: company.id } }">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.705" height="13.199"
                                     viewBox="0 0 21.705 13.199">
                                    <g>
                                        <g>
                                            <path
                                                d="M25.134 27.331C22.445 23.3 18.559 21 14.452 21S6.46 23.3 3.771 27.331L3.6 27.6l.171.269C6.46 31.9 10.346 34.2 14.452 34.2s7.993-2.3 10.681-6.331l.167-.269zM14.452 31.51a3.911 3.911 0 1 1 3.911-3.91 3.922 3.922 0 0 1-3.911 3.91z"
                                                transform="translate(-3.6 -21) translate(3.6 21) translate(-3.6 -21)"/>
                                        </g>
                                    </g>
                                </svg>
                            </router-link>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <transition name="fade">
                    <add-company-component v-if="showAddComponent" @closeModal="showAddComponent=false"/>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/axios";
import _ from "lodash";
import AddCompanyComponent from "@/components/companies/AddCompanyComponent";
import HeaderSearch from '@/mixins/HeaderSearch'
import {companyService} from "@/services/companyService";
import Multiselect from "@vueform/multiselect";

export default {
    name: "CompaniesComponent",
    mixins: [HeaderSearch],
    data() {
        return {
            search: '',
            page: '',
            showAddComponent: false,
        }
    },
    computed: {
        companies() {
            return this.$store.state.companies.companies
        },
        companyStatuses() {
            return this.$store.getters.companyStatuses
        }
    },
    created() {
        this.getCompanies()
    },
    methods: {
        searchFromHeader(keyword) {
            this.search = keyword;
            this.getCompanies();
        },
        getCompanies() {
            companyService.getCompanies({ search: this.search, page: this.page })
                .then(response => this.$store.commit('setCompanies', response.data.data))
                .catch(error => this.errors = error.response.data)
        },
        deleteCompany(company) {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                companyService.deleteCompany(company.id).then(response => company.active = false)
            }
        },
        companyStatusChanged(companyId, statusId) {
            companyService.updateCompanyStatus(companyId, statusId)
        },
        debounceGetCompanies: _.debounce(function () {
            this.getCompanies()
        }, 1000)
    },
    components: {
        AddCompanyComponent,
        Multiselect
    }
}
</script>

<style scoped>

</style>