<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Create Company') }}</h3>
                </div>
                <form @submit.prevent="addCompany" class="invite-form">

                    <div class="form-group">
                        <input v-model="user.company.name" :placeholder="$t('Company name')" type="text" name="company_name" :class="['form-control', {'has-error': errors['company.name']}]">
                        
                        <FormErrors v-if="errors[`company.name`]" :errors="errors[`company.name`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.profile.first_name" type="text" :placeholder="$t('First name')" name="first_name" :class="['form-control', {'has-error': errors['profile.first_name']}]">

                        <FormErrors v-if="errors[`profile.first_name`]" :errors="errors[`profile.first_name`]" />
                    </div>
                    <div class="form-group">
                        <input v-model="user.profile.last_name" :placeholder="$t('Last name')" type="text" name="last_name" :class="['form-control', {'has-error': errors['profile.last_name']}]">
                        
                        <FormErrors v-if="errors[`profile.last_name`]" :errors="errors[`profile.last_name`]" />
                    </div>
                    <div class="form-group">
                        <input v-model="user.email" type="email" :placeholder="$t('Email')" name="email" :class="['form-control', {'has-error': errors['email']}]">
                        
                        <FormErrors v-if="errors[`email`]" :errors="errors[`email`]" />
                    </div>
                    <div class="form-group">
                        <input v-model="user.profile.phone_number" type="text" :placeholder="$t('Phone')" name="phone" :class="['form-control', {'has-error': errors['phone_number']}]">
                        
                        <FormErrors v-if="errors[`phone_number`]" :errors="errors[`phone_number`]" />
                    </div>
<!--                    <div class="form-group">-->
<!--                        <input v-model="user.password" type="password" :placeholder="$t('Password')" :class="['form-control', {'has-error': errors['password']}]">-->

<!--                        <FormErrors v-if="errors[`password`]" :errors="errors[`password`]" />-->
<!--                    </div>-->
<!--                    <div class="form-group">-->
<!--                        <input v-model="user.password_confirmation" type="password" :placeholder="$t('Repeat password')" :class="['form-control', {'has-error': errors['password_confirmation']}]">-->
<!--                        -->
<!--                        <FormErrors v-if="errors[`password_confirmation`]" :errors="errors[`password_confirmation`]" />-->
<!--                    </div>-->
                    <div class="form-group">
                        <Multiselect
                            v-model="user.profile.language_id"
                            :options="languages"
                            :placeholder="$t('Select language')"
                            :class="['form-control', {'has-error': errors['profile.language_id']}]"
                            :searchable="true"
                        />
                        <FormErrors v-if="errors[`profile.language_id`]" :errors="errors[`profile.language_id`]" />
                    </div>
                    <div class="form-group">
                        <Multiselect
                            v-model="user.profile.country_id"
                            :options="countries"
                            :placeholder="$t('Select country')"
                            :class="['form-control', {'has-error': errors['profile.country_id']}]"
                            :searchable="true"
                        />
                        <FormErrors v-if="errors[`profile.country_id`]" :errors="errors[`profile.country_id`]" />
                    </div>

                    <button class="btn btn-primary">{{ $t('Create') }}</button>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import axios from "@/axios";
import Multiselect from '@vueform/multiselect'
import FormErrors from "@/components/widgets/FormErrors";
import Modal from "@/components/widgets/Modal";
import {companyService} from "@/services/companyService";

export default {
    name: "AddCompanyComponent",
    components: {
        Multiselect,
        FormErrors,
        Modal
    },
    data() {
        return {
            user: {
                profile: {},
                company: {},
            },
        }
    },
    computed: {
        countries() {
            return this.$store.getters.countries
        },
        languages() {
            return this.$store.getters.languages
        }
    },
    methods: {
        addCompany() {
            companyService.addCompany(this.user)
                .then(response => {
                    this.$store.commit('addCompany', response.data.data)
                    this.$emit('closeModal')
                })
                .catch(error => this.errors = error.response.data.errors || [])
        }
    }
}
</script>